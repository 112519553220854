import React, { useState } from "react";
import { getArticleCustomfields, getArticleInspectionTypes, actionProductAdd } from "../../helpers/global";
import { ValidateResponse } from "../../helpers/global";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../../components/Form/Fields";
import { FormInputSubmit } from "../../../components/Form/Input/Submit";
import { Message } from "../../../components/Message";
import { ContentWrapper } from "../../../components/Content/Wrapper";

export function AppProductsActionsAddarticle() {

    const navigate = useNavigate();
    const [state, setState] = useState('step1');
    const [error, setError] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({});
    const [customFields, setCustomFields] = useState();

    const getCustomFields = (articleId, ownerEntityId) => {

        getArticleCustomfields(articleId).then((res) => {

            const response = ValidateResponse(res.data);

            if (response.status === 'success') {

                let customFields = response.response;

                // Add inspections to customfields
                //actionProductAdd(postData).then((res) => {
                getArticleInspectionTypes({'articleId':articleId, 'ownerEntityId':ownerEntityId}).then((res2) => {

                    const response2 = ValidateResponse(res2.data);

                    if (response2.status === 'success') {

                        if (response2.response !== null && response2.response.inspectionTypes !== undefined) {

                            customFields.inspectionTypeId = {
                                type:'select',
                                properties: {
                                    label: "Direct keuren",
                                    options: response2.response.inspectionTypes,
                                    rules:['required']
                                }
                            }
                        }

                        // Check if there are inspections
                        setCustomFields(customFields);
                        setState('step2');
                    }
                    else {
                        setError(response.message);
                    }
                });
            }
            else {
                setError(response.message);
            }
        })
    }

    const handleInput = (name, value) => {
        let newValues = values;
        if (newValues.customFields === undefined) {
            newValues.customFields = {};
        }
        // add customfield
        if (customFields !== undefined && customFields[name] !== undefined) {
            newValues.customFields[name] = value;
        }
        else {
            newValues[name] = value;
        }
        
        setValues(newValues);

        if (name === 'articleCategory' || name === 'mainLocation') {

            if (newValues.articleCategory !== undefined
                && newValues.articleCategory.article !== undefined
                && newValues.articleCategory.article !== null
                && newValues.articleCategory.articleId > 0 
                && newValues.mainLocation !== undefined
                && newValues.mainLocation !== null) {

                getCustomFields(newValues.articleCategory.articleId, newValues.mainLocation);

            }
        }
        return;
    }

    const sendValues = () => {

        let postData = values;

        postData.articleId = values.articleCategory.articleId;

        let requiredValues = ['articleId', 'mainLocation', 'serialNumber'];

        // Validate required fields
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (postData[requiredField] === undefined) {
                setError('Controleer velden');
                validation[requiredField] = 'Verplicht veld';
            }
            else if (postData[requiredField] === null || postData[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            setFormErrors(validation);
            return;
        }

        actionProductAdd(postData).then((res) => {

            const response = ValidateResponse(res.data, 'product');
            if (response.status === 'success') {
                if(response.product.inspectionId !== undefined && response.product.inspectionId !== null){
                    navigate("/inspections/process/" + response.product.inspectionId);
                } else{
                    navigate("/products/" + response.product.context + "/details/" + response.product.productId);
                }
            }
            else if (response.response !== null && values[response.response] !== undefined) {
                let formErrors = {}
                formErrors[response.response] = response.message;
                setFormErrors(formErrors);
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error.data);
            setError(response.message);
        })
    }

    let fields = {
        'step1': {
            articleCategory: { type: 'categories', label: "Selecteer artikel", options: { output: "depot", category:"categoryId", article:"articleId"} },
            mainLocation: { label: {locationId: 'Klant'}, type: 'location', options: {locationId: 'mainLocation', parentId:1} },
        },
        'step2': {
            'location': { type: 'location', options: {locationId: 'locationId', departmentId:'departmentId', room:'room', parentId:values.mainLocation}},
            'clientName': { type: 'text', label: "Naam cliënt" },
            'serialNumber': {
                type: 'text', label: "Serienummer", rules: ["required"],
                info: "Serienummer mag bestaan uit hoofdletters, cijfers,koppelteken (-) of punt (.). Andere leestekens, spaties of speciale tekens worden genegeerd."
            },
            'frameNumber': { type: 'text', label: "Framenummer" },
            'registrationDate': { type: 'date', label: "Bouwjaar" , options: { min: "-125", max: null }, value: values['registrationDate']},
            'productBrand': { type: 'text', label: "Merk" },
            'productType': { type: 'text', label: "Type" },
            'dimensions': { type: 'text', label: "Afmetingen" },
            'remarks': { type: 'textarea', label: "Opmerkingen" },
        }
    }

    // Add custom fields
    if (customFields !== undefined) {
        Object.keys(customFields).forEach((key) => {
            fields.step2[key] = {
                type: customFields[key].type,
                label: customFields[key].properties.label,
                options: customFields[key].properties.options ?? null,
                rules: customFields[key].properties.rules ?? null,
            }
        });

    }

    console.log(fields.step2);

    let title = "Nieuw hulpmiddel";
    if (values.articleCategory !== undefined
        && values.articleCategory.article !== undefined
        && values.articleCategory.article !== null) {
        title += " | " + values.articleCategory.article.name;
    }

    const content = () => {
        return <>
            <h3 className="box-header">{title}</h3>
            <FormFields
                formFields={fields[state]}
                setValues={handleInput}
                setErrors={setFormErrors}
                formValues={values}
                formErrors={formErrors}
                identifier="product-add"
            />
            {state === 'step2' && customFields !== undefined &&
                <FormInputSubmit
                    handleSubmit={sendValues}
                    cancelUri={"/articles/actions"}
                    submitLabel={state === 'step1' ? "Volgende stap" : "Verzenden"}
                />
            }
        </>
    }

    return (
        <>
            <Message type="error" message={error} />
            <ContentWrapper
                type="outline-box"
                content={content()}
                className="outline-box--form"
            />
        </>
    );
}