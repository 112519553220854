import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AppProductsActions } from "../../app/Products/Actions";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesProductsActions({ parents, current, title }) {

    const location = useLocation();
    const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
    const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);
    const setGlobalMessage = (message, type) => {
        setMessage(message);
        setMessageType(type);
    }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />

            <PageHeader title={title}
                parents={parents}
                current={current}
                message={message}
                messageType={messageType}
                tabGroup="products"
            />

            <AppProductsActions
                current={current}
                redirectPath={"/" + parents}
                setGlobalMessage={setGlobalMessage} />
        </div>
    )
}