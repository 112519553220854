/**
 * @documentation APP access
 * All pages & read/write acces has to be registerd in ACL: SELECT * FROM acl WHERE resource like 'api_%' (api_customer or api_admin)
 * For the client app access is editable
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './scss/stylesheet.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );

// DO NOT USER serviceWorker until you know alle the pitfalls!
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();