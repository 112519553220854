import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../helpers/global";
import { fetchProducts } from "../helpers/articles";
//import { Link, useLocation } from "react-router-dom";
import { ListPagination } from "../../components/List/Pagination";
import { Message } from "../../components/Message";
import { List } from "../../components/List";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppProductsOverview({ current, identifier, setFilterFields, filterValues, setGlobalMessage, overviewType }) {

    let pageSize = 10; // default values ergens globaal opslaan?
    // const location = useLocation();
    // const [locations, setLocations] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    //const { type, message } = location.state != null ? location.state : "";
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [filterString, setFilterString] = useState("{}");
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('filters' + identifier)));
    const [data, setData] = useState();

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    let storedPageNr = getCurrentPage(current);
    if (pageNr !== storedPageNr) {
        setPageNr(storedPageNr);
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [];

    if (overviewType === 'Intern') {
        listColumns = [
            { key: "articleName", type: "link", path: "/products/" + current + "/details/:productId", id: "productId", hideView: "large" },
            { key: "articleName", label: "Artikelnaam", type: "text", hideView: 'small' },
            { key: "serialNumber", label: "Serienummer", type: "text" },
            { key: "statusDisplay", label: "Status", type: "text" },
            { key: "warehouse", label: "Magazijn", type: "text" },
            { key: "registrationDate", label: "Bouwjaar", type: "date" },
            { key: "productId", label: "", type: "link", path: "/products/" + current + "/details/:productId", id: "productId", hideView: "small" }
        ];
    }

    if (overviewType === 'Extern') {
        listColumns = [
            { key: "articleName", type: "link", path: "/products/" + current + "/details/:productId", id: "productId", hideView: "large" },
            { key: "articleName", label: "Artikelnaam", type: "text", hideView: 'small' },
            { key: "serialNumber", label: "Serienummer", type: "text" },
            { key: "statusDisplay", label: "Status", type: "text" },
            { key: "owner", label: "Producteigenaar", type: "text" },
            { key: "registrationDate", label: "Bouwjaar", type: "date" },
            { key: "productId", label: "", type: "link", path: "/products/" + current + "/details/:productId", id: "productId", hideView: "small" }
        ];
    }

    const defineFilters = () => {
        let qualityOptions = [
            { label: 'Goed', value: "GOOD" },
            { label: "Matig", value: "AVERAGE" },
            { label: "Slecht", value: "BAD" }]

        let statusOptionsIntern = [
            { label: 'Beschikbaar', value: "AVAILABLE" },
            { label: "Reiniging", value: "CLEANING" },
            { label: "Reparatie", value: "REPAIR" },
            { label: "Verhuurd", value: "RENTED" },
            { label: "Verhuurd in zorgcrm", value: "RENTED-SYNC" },
            { label: "AD-dossier", value: "ADDOSSIER" },
            { label: "Gereserveerd", value: "RESERVED" },
            { label: "Onbekend", value: "UNKNOWN" }]

        let statusOptionsExtern = [
            { label: 'Beschikbaar in depot', value: "DEPOT-IN" },
            { label: "Op klantlocatie", value: "DEPOT-OUT" },
            { label: "In ontwikkeling", value: "PENDING" },
            { label: "Reparatie", value: "REPAIR" },
            { label: "Reiniging", value: "CLEANING" }]

        let filterFields = {}
        // The filters are set here and send to parent onloaded
        if (overviewType === 'Intern') {
            filterFields = {
                'category': { type: 'categories', label: "Artikel/categorie", options: { output: 'intern', category: 'categoryId', article: "articleId" } },
                'warehouse': { type: 'warehouse' },
                'serialNumber': { type: 'text', 'label': "Serienummer" },
                'frameNumber': { type: 'text', 'label': "Framenummer" },
                'status': { type: 'select', 'label': 'Status', options: statusOptionsIntern },
                'registrationDateFrom': { type: 'text', 'label': "Bouwjaar van" },
                'registrationDateTo': { type: 'text', 'label': "Bouwjaar tot" },
                'quality': { type: 'select', 'label': "Kwaliteit", options: qualityOptions }
            }
            setFilterFields(filterFields);
        }

        if (overviewType === 'Extern') {
            filterFields = {
                'category': { type: 'categories', label: "Artikel/categorie", options: { output: 'extern', category: 'categoryId', article: "articleId" } },
                'location': { type: 'location' },
                
                'serialNumber': { type: 'text', 'label': "Serienummer" },
                'frameNumber': { type: 'text', 'label': "Framenummer" },
                'status': { type: 'select', 'label': 'Status', options: statusOptionsExtern },
                'registrationDateFrom': { type: 'text', 'label': "Bouwjaar van" },
                'registrationDateTo': { type: 'text', 'label': "Bouwjaar tot" },
                'quality': { type: 'select', 'label': "Kwaliteit", options: qualityOptions }
            }
            setFilterFields(filterFields);
        }
    }

    useEffect(() => {

        const callData = {
            filters: {
                context: current,
                tabType: overviewType !== undefined ? overviewType : '',
                limitOffset: pageSize * pageNr - (pageSize - 1),
                limitRows: pageSize,
                ...filters
            }
        }
        fetchProducts(callData).then((res) => {
            const response = ValidateResponse(res.data, 'products');
            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {
                const data = res.data;
                setData(data.products);
                setTotalCount(data.totalCount)
            }
            setIsLoaded(true);
            defineFilters(); // activate filters if data is loaded
        }).catch((error) => {
            const response = ValidateResponse(error, 'products');
            setGlobalMessage(response.message, response.status);
        })
    }, [filters, setData, pageNr, setTotalCount, setIsLoaded, setData, isLoaded, current, pageSize])

    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (isLoaded === true && (data === undefined || data === null || Object.keys(data).length === 0)) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <>
                {/* <div className="col-12"> Deze geeft een layout error! marges klopeen niet meer*/}

                <List
                    columns={listColumns}
                    data={data}
                // identifier="customers" //? customers?? waar komt dat vandaan?
                />
                <ListPagination
                    className="pagination-bar"
                    currentPage={pageNr}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    setCurrentPage={handlePagination}
                />

                {/* </div> */}
            </>
        </>)
}