import React, {} from "react";
import { Link} from "react-router-dom";
import { ContentWrapper } from "../../components/Content/Wrapper";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppProductsActions({redirectPath}) {

    const content = () => {
        return (
            <>
                <div className="tiles__column">
                    <Link to={redirectPath+"/addarticle/"} className={"tile tile__registreren"}>
                        <span className="icon"></span>
                        <span className="tile__title">
                            Hulpmiddel<br />Toevoegen
                        </span>
                    </Link>
                </div>
            </>
        )
    }

    return (
        <ContentWrapper
            type="tiles-secondary"
            content={content()}
        />
    );
}